var pawLibrary = {
	map: false,
	fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	fullMonthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	offset: 0.8,
	setPayments: function(clientToken) {
		$('#loader').hide();

		braintree.setup(clientToken, "dropin", {
		  container: "payment-form"
		});
	},
	addToCart: function(btns)
	{
		var options_val = $('#options_sel'); // variants
		if(options_val.length > 0) {
			options_val.change(function() {
				var selected = $(this).val();
				var price = $('#price' + selected);
				var value = price.attr('data-price-item');
				// CHANGE PRICES
				if(price.length > 0) {
					$('.price-wrap .price').addClass('hide');
					price.removeClass('hide');
					$('.add-to-cart').attr('data-variant', selected).attr('data-price', value);
					$('.price-wrap input').val(selected);
				}
			});
		}

		btns.on('click', function(e) {
			e.preventDefault();
			var url = './ajax/addToCart.inc.php';
			var selected = $(this);
			var catId = $('input[name="category"]').val();
			var productId = selected.attr('data-product');
			var productPrice = ($('#price' + catId).length == 1) ? $('#price' + catId).attr('data-price-item') : 0;
			var qty = $('#qty_sel').val();

			var data = {};
					data["category"] = catId;
					data["product"] = productId;
					data["qty_sel"] = qty;
		
			$.ajax({
				type: "POST",
				cache: false,
				url: url,
				data: data,
				success:function(response){
					$.fancybox.open(response);

					var counter = $('#counter').attr('data-total-num');
					var price = $('#counter-price').attr('data-total-price');
					var productPrice = ($('#price' + data.category).length == 1) ? $('#price' + data.category).attr('data-price-item') : 0;

					
					counter = parseInt(counter) + parseInt(qty);
					price = parseFloat(price) + productPrice * parseInt(qty);
					price = price.toFixed(2);
					
					$('#counter').attr('data-total-num', counter).html(counter);
					$('#counter-mobile').attr('data-total-num', counter).html(counter);
					$('#counter-price').attr('data-total-price', price).html("&pound" + price);
	
					$('#continue').click(function(e) {
						e.preventDefault();
						$.fancybox.close();
					});
				}
			});

		});

		return false;
	},
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');


			if($('#subMenu' + id).length > 0)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
  			$('.dropdown-menu').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});

		// hide mega menu
		$(document).click(function(e) {
			if($('#showMenu7').hasClass('highlight') == true) {
				var $container = $("header.page-header .mid");
		    if (!$container.is(e.target) && $container.has(e.target).length === 0) {
		       $('#showMenu7').trigger('click');
		    }
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if(status == false)
				{
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					target.slideUp(function() {
						target.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var btn = $('#request');
		
		if(btn.length > 0) {
			btn.fancybox({
				afterLoad: function() {
					var url = window.location.href;
							url = url.split('#');
							url = url[0];

					var tmp = $('#productTitle').html();

					$('#url_txt').val(url);
					$('#title_txt').val(tmp);
				},
				onComplete: function( instance, slide ) {

					var loading = $('#loader');
					var form = $('#enquiry-frm');

					var name_txt = form.find('#name_txt');
					var email_txt = form.find('#email_txt');
					var msg_txt = form.find('#msg_txt');

					var name_err = form.find('#error-name');
					var email_err = form.find('#error-email');
					var msg_err = form.find('#error-msg');

					$('#send_msg_btn').click(function(e) {
						e.preventDefault();
						var errors = false;

						// validation
						if(name_txt.val() == '') {

							name_txt.addClass('error-input');
							name_err.fadeIn();
							errors = true;
						} else {
							name_txt.removeClass('error-input');
							name_err.hide();
						}
						if(!pawLibrary.isValidEmailAddress(email_txt.val())) {
							email_txt.addClass('error-input');
							email_err.fadeIn();
							errors = true;
						} else {
							email_txt.removeClass('error-input');
							email_err.hide();
						}
						if(msg_txt.val() == '') {
							msg_txt.addClass('error-input');
							msg_err.fadeIn();
							errors = true;
						} else {
							msg_txt.removeClass('error-input');
							msg_err.hide();
						}

						// sent
						if(errors == false) {
							loading.removeClass('hide').fadeIn();
							form.hide();
							var formData = $('#enquiry-frm').serialize();
							$.ajax({
								url: './ajax/sendEnquiry.inc.php?init=sendEnquiry',
								data: formData,
								method: 'POST'
							}).done(function(response) {
								$('#loader').html("Your message has been sent");
							});
						}
					});
				}
			});
		}
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		if($('#tabs').length > 0) {
			var width = $(window).width();
			var tabs = $('#tabs');
			var btns = tabs.find('.tab-btn');
			var anchors = tabs.find('a');
			var items = $('.content-wrap');
			
			btns.click(function() {
				var active = $(this).attr('data-tab-btn');
				btns.removeClass('active');
				items.removeClass('show');
				btns.find('attr');
				$('.tab-btn[data-tab-btn="' + active + '"]').addClass('active');
				$('.content-wrap[data-tab-content="' + active + '"]').addClass('show');
			});


		}

	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#thumbs').length > 0 ) {
			$('#gallery').on('cycle-next cycle-prev', function(e, opts) {
				$('#thumbs').cycle('goto', opts.currSlide);
			});

			$('#thumbs span.thumb-wrap').click(function(){
				var index = $('#thumbs').data('cycle.API').getSlideIndex(this);
				$('#gallery').cycle('goto', index);
			});
		}
	},
	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	isValidEmailAddress: function(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
	},

	getDialCode: function()
	{
		var getCode = $('.selected-flag .selected-dial-code');
		return getCode.html();
	},
	
	deliveryFrm: function()
	{
		var delivery_dropdown = $('.delivery_sel');
		var delId = 0;
		delivery_dropdown.on('change', function() {
			delId = delivery_dropdown.val();
			$('#del_lookup').submit();
		});
		
	},


	deleteItem: function(e) {
		var response = confirm('Are you sure you want to remove this item from your cart?');
		if(response == false) {
			e.preventDefault();
		}
	},


	deliveryFrm: function()
	{
		var delivery_dropdown = $('.delivery_sel');
		var delId = 0;
		delivery_dropdown.on('change', function() {
			delId = delivery_dropdown.val();
			$('#del_lookup').submit();
		});

	},


	deliverySetup: function(options)
	{
		$('#del_btn').addClass('hidden');
		var country_sel = new Array();
		var regions_sel = new Array();
		var variant_sel = new Array();
		var x = -1;

		$.each(options, function(key, option) {
			var country = "";
			var region = "";
			var variant = "";

			var tmp = $.trim($(option).text()).split(' - ');

			if( tmp.length == 1 ) {
				country = tmp[0].split(' - ');
				variant = country[1];
				country = country[0];
			} else {
				country = tmp[1].split(' - ');
				variant = country[1]
				region = country[0];
				country = tmp[0];
			}

			country = country.split(" = ");
			country = country[0];

			country = $.trim(country);


			if( $.inArray(country, country_sel) == -1 )
			{
				country_sel.push(country);
				x++;
				regions_sel[x] = new Array();
				variant_sel[x] = new Array();
			}

			if(region != '') {
				var countryId = $.inArray(country, country_sel);
				if( $.inArray(region, regions_sel[countryId]) == -1 )
				{
					regions_sel[countryId].push(region);
				}
			}


		});

		//CREATE DROPDOWNS
		var html = '<div class="select_style">';
				html += '<select onchange="pawLibrary.updateDelivery()" id="country_sel"><option value="0">Select</option>';
		var additional_html = '';
			$.each(country_sel, function(key, value) {
				html += '<option value="'+(key + 1)+'">';
				value = value.split(' = ');
				html += value[0];
				html += '</option>';
				if( regions_sel[key].length > 0 )
				{
					additional_html += '<div id="region_sel_'+(key + 1)+'" class="select_style hidden region_sel"><select onchange="pawLibrary.updateDelivery()" name="region_sel['+(key + 1)+']">';
					additional_html += '<option value="0">Select</option>';
						$.each(regions_sel[key], function(regionId, region) {
							region = region.split(' = ');
							additional_html += '<option value="'+region[0]+'">';
								additional_html += ' - ' + region[0];
							additional_html += '</option>';

						});
					additional_html += '</select></div>';
				}

			});
			html += '</select>';
		html += '</div>';

		var frm = $('#del_lookup');

		//$('#del_lookup .basket-delivery .row').prepend(additional_html);
		//$('#del_lookup .basket-delivery .row').prepend(html);

		$('<div id="delivery_dropdowns_wrap"></div>').insertAfter('.basket-delivery .lbl');
		$('#delivery_dropdowns_wrap').append(html);
		$('#delivery_dropdowns_wrap').append(additional_html);

		$('.delivery_sel').attr('style', 'display:none;');

	},

	updateDelivery: function()
	{
		$('.region_sel').addClass('hidden');

		var select = $('#country_sel option:selected');
		var subregions = $('#region_sel_' + select.val());
		var del_frm_sel = $('.delivery_sel');
		var tmp = select.text().split('- ');
		var lookup = tmp[1];
		var trigger = false;
		var variant_sel = Array();

		if(subregions.length > 0)
		{
			$('#del_btn').addClass('hidden');
			var subRegionSelect = subregions.find('select').val();
			if( subRegionSelect != 0 )
			{
				lookup += ' - ' + subRegionSelect;
				trigger = true;
			}
			subregions.removeClass('hidden');
		}
		else
		{
			trigger = true;
		}



		countryId = 0;
		if( trigger == true )
		{
			$('#del_variants_wrap').addClass('hidden'); // REMOVE VARIANTS ON COUNTRY CHANGE
			var options = $('.delivery_frm option');
			$.each(options, function(key, row) {
				var tmp = $(row).text().split(' - ');
				var country = $.trim(tmp[0]);
					country = country.replace('- ', '');

				if(country == lookup)
				{
					variant_sel.push(tmp[1]);
				}
			});


			if( variant_sel.length > 1 )
			{
				var variant_sel_html = '<select name="variant_sel" id="variant_sel">';
					variant_sel_html += '<option value="0">Select</option>';
					$.each(variant_sel, function(key, row) {
						//row = row.split(' = ');
						//row = row[0]
						variant_sel_html += '<option value="'+key+'">';
						variant_sel_html += ' - ' + row;
						variant_sel_html += '</option>';
					})
					variant_sel_html += '</select>';

				var target = $('#del_variants_wrap');
					target.html(variant_sel_html);
					target.removeClass('hidden');

				$('#variant_sel').change( function() {
					// VARIANTS
					var selected = lookup + $('#variant_sel option:selected').text();
						selected = $.trim(selected);
						$.each(options, function(key, row) {
							var row = $(row);
								txt = row.text().split(' = ');
								txt = txt[0].replace('- ', '');
								txt = $.trim(txt);
								value = row.val();
								if(txt == selected)
								{
									$(".delivery_frm option[value='"+value+"']").prop('selected','selected');
									//pawLibrary.lookUpDeliveryCost();
								}
						});
					$('#del_btn').removeClass('hidden');
				});
			} else {
				// NO VARIANTS
				var selected = lookup;
					selected = $.trim(selected);
					$.each(options, function(key, row) {
						var row = $(row);
							txt = row.text().split(' = ');
							txt = txt[0].replace('- ', '');
							txt = $.trim(txt);
							value = row.val();
							if(txt == selected)
							{
								$(".delivery_frm option[value='"+value+"']").prop('selected','selected');
								//pawLibrary.lookUpDeliveryCost();
							}
					});

					//$('#del_btn').trigger('click');
				//$('#del_btn').removeClass('hidden');
					$('#del_lookup').submit();

			}
		}

	},

	
	sameAsBilling: function()
	{
		var chk = $('#same_as_billing').is(":checked");
		var frm = $('#del-address-frm');
		var required = $('#del_name_txt,#del_add1_txt,#del_city_txt,#del_country_txt,#del_pcode_txt,#del_phone_txt');
		if(chk == true) {
			frm.hide();
			required.removeAttr('required');
		} else {
			frm.slideDown();
			required.attr('required','required');
		}
	},
 
	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		pawLibrary.jumpToLinks();
		pawLibrary.dropdownMenus();
		pawLibrary.setGallery();
		pawLibrary.setTabs();

		if($('.qty').length > 0) {
			var target = $('#qty_sel');
			$('#qty-minus').click(function() {
				var currentQty = target.val();
				if(currentQty > 1) {
					currentQty--;
					target.val(currentQty);
				}
			});
			$('#qty-plus').click(function() {
				var currentQty = target.val();
				currentQty++;
				target.val(currentQty);
			});
			
		}
		
		if($('.updateQty').length > 0) {
			$('.updateQty').change(function() {
				this.form.submit();
			});
		}
		
		var same_as_billing = $('#same_as_billing')
		if(same_as_billing.length > 0) {
			same_as_billing.click( function() {
				pawLibrary.sameAsBilling();
			});
		}

		var remove_from_cart_btn = $('.remove_from_cart_btn');
		if(remove_from_cart_btn.length > 0) {
			remove_from_cart_btn.click( function(e) {
				pawLibrary.deleteItem(e);
			});
		}
		var delivery_dropdown = $('#delivery_sel');
		if(delivery_dropdown.length > 0)
		{
			pawLibrary.deliveryFrm();
			var delivery_dropdown = $('.delivery_sel option');
			if(delivery_dropdown.length > 1)
			{
				pawLibrary.deliverySetup(delivery_dropdown);
			}
		}

		var cart_btns = $('.add-to-cart');
		if(cart_btns.length > 0)
		{
			pawLibrary.addToCart(cart_btns);
		}

		if($('#tabs').length > 0) {
			var tabs = $('#tabs-btn span');
			var tabsContent = $('.tab-content-inner');
			tabs.click(function() {
				var selected = $(this).attr('data-tab-btn');
				tabs.removeClass('selected');
				$('*[data-tab-btn="' + selected + '"]').addClass('selected');
				//
				tabsContent.hide().addClass('hidden');
				$('#tab' + selected + '-txt').removeClass('hidden').fadeIn();
			});

			var topLevel = $('a.toggle');
			topLevel.click(function(e) {
		  	e.preventDefault();
		    var $this = $(this);
				topLevel.removeClass('active');
		    if ($this.next().hasClass('show')) {
		        $this.next().removeClass('show');
		        $this.next().slideUp(350);
		    } else {
		        $this.parent().parent().find('li .inner').removeClass('show');
						$this.parent().parent().find('li .inner').slideUp(350);
		        $this.next().toggleClass('show');
		        $this.next().slideToggle(350);
						$($this[0]).addClass('active');
		    }
			});
		}

		if($('#gallery').length > 0) {
			pawLibrary.productGallery();
		}

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		if($('#mobile-submenu').length > 0 ) {
			$('#mobile-submenu').change(function() {
				var selected = $(this).val();
				var base = $('base').attr('href');
				window.location = base + selected.replace("./", "");
			});
		}

		if( $('.filterItems').length > 0 )
		{
			$('.filterItems').on('change', function() {
				pawLibrary.productSearch();
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

	}

}


$(window).load(function() {
	pawLibrary.init();
});
